<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              :src="skin === 'dark'? appFullLogoImageWhite : appFullLogoImage"
              alt="logo"
            />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    const { skin } = useAppConfig()
    // App Name
    const { appName, appFullLogoImage, appFullLogoImageWhite } = $themeConfig.app
    return {
      appName,
      appFullLogoImage,
      appFullLogoImageWhite,
      skin,
    }
  },
}
</script>

<style scoped>
.navbar-brand{
  margin: 0 !important;
}
.brand-logo{
    width: 180px;
    height: 35px;
}
.brand-logo img{
    max-width: 180px !important;
}
.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
    left: calc(50% - 90px);
}
</style>
